import React from "react"

export const WorkplaceContext = React.createContext()

export class WorkplaceProvider extends React.Component {
  state = {
    workplace: 2,
  }

  // LOG IN USER
  setWorkplace = workplaceName => {
    this.setState({
      workplace: workplaceName,
    })
  }

  render() {
    return (
      <WorkplaceContext.Provider
        value={{
          state: this.state,
          setWorkplace: this.setWorkplace,
        }}
      >
        {this.props.children}
      </WorkplaceContext.Provider>
    )
  }
}
