import React, { useContext } from "react"

import bill from "../../../images/bill_small2.png"
import rope from "../../../images/bill_small2rope.png"
// "../../../images/bill_small2.png"
import workStyles from "./work.module.scss"
import { WorkplaceContext } from "../../../context/workplace"

const Work = () => {
  const { state: { workplace = {} } = {} } = useContext(WorkplaceContext) || {}
  return (
    <>
      <div className={workStyles.container}>
        <div
          className={workStyles.cartrawler}
          style={workplace === 2 ? { opacity: 1 } : null}
        />
        <div
          className={workStyles.wbt}
          style={workplace === 1 ? { opacity: 1 } : null}
        />
        <div
          className={workStyles.ibm}
          style={workplace === 0 ? { opacity: 1 } : null}
        />
        <div className={workStyles.splash}>
          <div className={workStyles.aligner}>
            <h1>#Work</h1>
            <h2>
              If you love your job, <br />
              you never work a <br />
              day in your life.
            </h2>
          </div>
        </div>
      </div>
      <img src={bill} alt="A cartoon of Bill" className={workStyles.bill2} />
      <img src={rope} alt="A rope" className={workStyles.bill2rope} />
    </>
  )
}

export default Work
